import * as React from 'react';
import {Menu} from 'antd';
import {CriblCloudIcon} from 'maestro-ui/src/components/Icons/CriblCloudIcon';
import {UserOutlined} from '@ant-design/icons';
import {Link} from 'gatsby';
// @ts-ignore
import * as sharedStyles from '../../styles/shared.module.less';
import {CriblCloudRole} from 'shared/src/user/types';
import {useAuth} from '../Auth/AuthContext';

export const TopNav = () => {
  const {user} = useAuth();
  return (
    <Menu theme='dark' mode='horizontal' className={sharedStyles.topNavEdge}>
      <Menu.Item key={'home'}>
        <div className={sharedStyles.criblLogo}>
          <CriblCloudIcon aria-label='Cribl.Cloud' style={{height: '46px', width: '100%'}} />
        </div>
      </Menu.Item>
      {user?.['https://cribl.cloud/cloudRole']?.find(
        (role) => role === CriblCloudRole.Admin || role === CriblCloudRole.Support
      ) && (
        <>
          <Menu.Item key={'/organizations'}>
            <Link to='/organizations'>Select Organization</Link>
          </Menu.Item>
          <Menu.Item key={'/admin/notifications'}>
            <Link to='/admin/notifications'>Notifications</Link>
          </Menu.Item>
        </>
      )}
      <Menu.ItemGroup className={sharedStyles.rightMenu}>
        <Menu.SubMenu
          // className={sharedStyles.subMenu}
          popupOffset={[8, 0]}
          key='AccountMenu'
          icon={<UserOutlined />}
          title='Account'
          data-testid='account-menu-item'
        >
          <Menu.Item key='sign-out'>
            <Link to={'/logout'}>Log Out</Link>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu.ItemGroup>
    </Menu>
  );
};
