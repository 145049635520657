import * as React from 'react';
import {FC} from 'react';
// @ts-ignore
import {adminGetNotification, adminGetNotifications, adminPostNotifications} from '../../services/AdminAPI';
import {Button, Form, Select, Tag, Input, Space, message, Tooltip, Radio, Popconfirm, Table} from 'antd';
import {ExportOutlined, PlusOutlined, CopyOutlined, CloseOutlined} from '@ant-design/icons';
import {CourierNotification} from './types';
import {useAuth} from '../Auth/AuthContext';

const testEventProfile = {
  name: 'Jane ',
  given_name: 'Jane',
  family_name: '',
  email: 'user@localhost.localdomain',
  custom: {
    company: 'Cribl'
  },
  firstName: 'Jane',
  lastName: '',
  username: '2bc7b345-dbec-4ddb-ad2c-63aa977ae142'
};

const stringToColor = function (str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
};

const errorColumns = [
  {
    title: 'Organization ID',
    dataIndex: 'recipientList',
    key: 'recipientList',
    render: (text) => text.split('.')[1]
  },
  {
    title: 'Error',
    dataIndex: 'error',
    key: 'error'
  }
];

export const Notifications: FC = () => {
  const {authToken} = useAuth();
  const [form] = Form.useForm();
  const [notifications, setNotifications] = React.useState([]);
  const [notification, setNotification] = React.useState<CourierNotification | null>();
  const [selectOrganizations, setSelectOrganizations] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const [courierUrl, setCourierUrl] = React.useState<string>();
  const [domain, setDomain] = React.useState<string>();
  const [visible, setVisible] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  const handleCancel = () => {
    setVisible(false);
  };
  const initialize = async () => {
    setCourierUrl(`https://app.courier.com${window?.location?.hostname === 'portal.cribl.cloud' ? '' : '/test'}`);
    setDomain(window?.location?.hostname.split('.').splice(-2, 1)[0]);

    const response = await adminGetNotifications({authToken});
    if (response.ok) {
      const data = await response.json();
      setNotifications(data);
    } else if (response.status === 401) {
      window.location.href = '/';
    }
  };

  React.useEffect(() => {
    void initialize();
  }, []);

  const loadNotification = async (notificationId) => {
    const response = await adminGetNotification({notificationId, authToken});
    if (response.ok) {
      const data: CourierNotification = await response.json();
      // find variables
      const variables = [];
      data.blocks.forEach((block) => {
        if (!block.content) return;
        // @ts-ignore
        const matches = Array.from(block.content.matchAll(/{([a-zA-Z0-9_.]+)}/g), (m) => m[1]);
        matches.forEach((match) => {
          if (match && !variables.includes(match)) variables.push(match);
        });
      });
      setNotification({...data, variables});
    } else if (response.status === 401) {
      window.location.href = '/';
    }
  };

  const getOrgIds = (value?: string) => {
    if (!selectOrganizations) return {orgIds: ['*'], invalidOrgIds: undefined};
    let orgIds = (value || form.getFieldValue('orgIds'))?.split(/[,\s]+/);
    orgIds = orgIds.filter(Boolean); // filter falsy
    if (!orgIds) return {orgIds, invalidOrgIds: undefined};
    const invalidOrgIds = orgIds.filter((orgId) => !orgId.match(/^[a-z]+-[a-z]+(?:-[a-z0-9]+)?$/));
    return {orgIds, invalidOrgIds};
  };

  const buildListRecipients = (orgIds: string[], role: string) => {
    return orgIds.map((orgId) => `${domain}.${orgId}.${role}`);
  };

  const handleVisibleChange = (v) => {
    if (!v) return;
    if (selectOrganizations) {
      form.submit();
    } else {
      setVisible(true);
    }
  };

  const onFinish = async (values: any) => {
    // if they've selected all orgs display a confirmation popup
    if (!selectOrganizations && !visible) return setVisible(true);
    setSubmitting(true);
    const {orgIds} = getOrgIds();
    const recipients = buildListRecipients(orgIds, values['role']);
    const response = await adminPostNotifications({
      notificationId: values['notificationId'],
      recipients,
      data: values['data'],
      authToken
    });
    const data = await response.json();
    if (response.ok) {
      message.info(data.message);
      if (data.results.find((res) => res.error)) {
        message.warning('One or more errors occurred.');
        const errors = data.results.filter((result) => result.error);
        setErrors(errors);
        // remove successful org id's from field to stop from resending
        form.setFields([
          {name: 'orgIds', value: errors.map((error) => error?.recipientList?.split('.')[1]).join(', ')}
        ]);
      } else {
        form.resetFields();
        setNotification(null);
        setSelectOrganizations(true);
        setErrors([]);
      }
    } else {
      message.error(data.message || 'Error sending notifications');
    }
    setSubmitting(false);
    setVisible(false);
  };

  const addVariable = () => {
    const name = prompt('Enter variable name');
    if (!name) return;
    if (name === 'organizationId') {
      return message.error(
        'organizationId is a reserved variable, it will be populated with the organizationId of the list.'
      );
    }
    setNotification((prevState) => ({...prevState, variables: [...prevState.variables, name]}));
  };

  const removeVariable = (name) => () => {
    setNotification((prevState) => ({...prevState, variables: prevState.variables.filter((v) => v !== name)}));
  };

  const copyTestEvent = () => {
    const testEvent = {
      courier: {},
      data: {...form.getFieldValue('data'), organizationId: 'goofy-panini'},
      profile: testEventProfile,
      override: {}
    };
    void navigator.clipboard.writeText(JSON.stringify(testEvent, null, 2));
    void message.info('Test event copied to clipboard');
  };

  const handleTargetOrganizations = (e) => {
    setSelectOrganizations(e.target.value === 'select');
  };

  return (
    <div>
      <h1>Notifications</h1>
      <p>
        Notification templates that have been created in Courier will be listed below. If you need to create a new
        template use the
        <a href={`${courierUrl}/designer/notifications`} target='courier'>
          Courier Notification Designer
        </a>
        .
      </p>
      {errors.length > 0 && <Table dataSource={errors.filter((result) => result.error)} columns={errorColumns} />}
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Form.Item label='Notification template' required>
          <Input.Group compact>
            <Form.Item name='notificationId' noStyle rules={[{required: true}]}>
              <Select
                onSelect={loadNotification}
                placeholder='Select a notification template'
                style={{width: 'calc(100% - 245px)'}}
              >
                {notifications.map((n) => (
                  <Select.Option key={n.id} value={n.id}>
                    {n.title}{' '}
                    {n.id === form.getFieldValue('notificationId') &&
                      notification.channels.map((channel) => (
                        <Tag key={channel.id} color={stringToColor(channel.type)}>
                          {channel.type}
                        </Tag>
                      ))}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {notification && (
              <Button
                type='link'
                href={`${courierUrl}/designer/notifications/${form.getFieldValue('notificationId')}`}
                target='courier'
                icon={<ExportOutlined />}
              >
                View in Notification Designer
              </Button>
            )}
          </Input.Group>
        </Form.Item>

        <div style={{width: 'calc(100% - 245px)'}}>
          <Form.Item label='Recipient Organizations' name='targetOrganizations' required>
            <Radio.Group defaultValue='select' buttonStyle='solid' onChange={handleTargetOrganizations}>
              <Radio.Button value='select'>Select Organizations</Radio.Button>
              <Radio.Button value='all'>All</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {selectOrganizations && (
            <Form.Item
              label={`Organization IDs`}
              name='orgIds'
              required
              rules={[
                {
                  required: true,
                  validator: (_rule, value) => {
                    const {invalidOrgIds} = getOrgIds(value);
                    return invalidOrgIds?.length > 0
                      ? Promise.reject(
                          new Error(
                            `Invalid Organization ID${invalidOrgIds.length > 1 ? 's' : ''}: ${invalidOrgIds.join(', ')}`
                          )
                        )
                      : Promise.resolve();
                  }
                }
              ]}
            >
              <Input.TextArea
                placeholder='Enter Organization IDs seperated by commas or whitespace'
                autoSize={{maxRows: 15}}
              />
            </Form.Item>
          )}
          <Form.Item name='role' label='Roles' required rules={[{required: true}]}>
            <Select>
              <Select.Option value='owner'>Owners</Select.Option>
              <Select.Option value='*'>All Roles</Select.Option>
            </Select>
          </Form.Item>
          {notification?.variables?.length > 0 && <h2>Variables</h2>}
          {notification?.variables?.map((variable) => {
            if (variable.startsWith('profile.') || variable === 'organizationId') return null;
            return (
              <Form.Item key={variable} label={variable}>
                <Input.Group compact>
                  <Form.Item name={['data', variable]} style={{width: 'calc(100% - 32px)'}}>
                    <Input />
                  </Form.Item>
                  <Tooltip title='Remove variable'>
                    <Button icon={<CloseOutlined />} onClick={removeVariable(variable)} />
                  </Tooltip>
                </Input.Group>
              </Form.Item>
            );
          })}
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>
              <Space>
                <Button icon={<PlusOutlined />} onClick={addVariable} disabled={submitting}>
                  Add Variable
                </Button>
                <Button onClick={copyTestEvent} icon={<CopyOutlined />} disabled={submitting}>
                  Copy Test Event to Clipboard
                </Button>
              </Space>
            </div>
            <div>
              <Popconfirm
                title='Send notification to All Organizations?'
                visible={visible}
                onConfirm={form.submit}
                okButtonProps={{loading: submitting}}
                onCancel={handleCancel}
                onVisibleChange={handleVisibleChange}
              >
                <Button type='primary' htmlType='submit' loading={submitting}>
                  Send Notifications
                </Button>
              </Popconfirm>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
