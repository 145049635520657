import * as React from 'react';
import {TopNav} from '../TopNav/TopNav';
import {Layout} from 'antd';
const {Content} = Layout;
// @ts-ignore
import * as sharedStyles from '../../styles/shared.module.less';

export const NavLayout = ({children}) => {
  return (
    <Layout>
      <nav>
        <TopNav />
      </nav>
      <Content className={sharedStyles.container}>{children}</Content>
    </Layout>
  );
};
