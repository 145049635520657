import {getXSRFToken} from './API';

export const adminGetNotifications = async ({authToken}) => {
  return await fetch(`${process.env.GATSBY_API_URL}/api/admin/notifications`, {
    method: 'GET',
    headers: {
      ...getXSRFToken(),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`
    },
    mode: 'cors',
    credentials: 'include'
  });
};

export const adminGetNotification = async ({notificationId, authToken}) => {
  return await fetch(`${process.env.GATSBY_API_URL}/api/admin/notifications/${notificationId}`, {
    method: 'GET',
    headers: {
      ...getXSRFToken(),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`
    },
    mode: 'cors',
    credentials: 'include'
  });
};

export const adminPostNotifications = async ({notificationId, recipients, data, authToken}) => {
  return await fetch(`${process.env.GATSBY_API_URL}/api/admin/notifications/send/list`, {
    method: 'POST',
    headers: {
      ...getXSRFToken(),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`
    },
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({
      notificationId,
      recipients,
      data
    })
  });
};
