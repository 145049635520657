export enum CriblCloudRole {
  Admin = 'CriblCloudAdmin',
  SE = 'CriblCloudSE',
  Support = 'CriblCloudSupport',
  'Developer' = 'CriblCloudDeveloper'
}
export const AllCloudRoles = Object.values(CriblCloudRole);
export const CloudAdminRoles = [CriblCloudRole.Admin, CriblCloudRole.Support];
export const OrganizationCloudRolesValues = [CriblCloudRole.Admin, CriblCloudRole.SE, CriblCloudRole.Developer];
export type OrganizationCloudRoles = typeof OrganizationCloudRolesValues[number];
