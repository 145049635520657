import * as React from 'react';
import '../../styles/App.less';
import {Helmet} from 'react-helmet';
import {setUTMCookie} from '../../utils/CookieUtil';
import {PageProps} from 'gatsby';
import {Result} from 'antd';
import {Notifications} from '../../components/Admin/Notifications';
import {CriblCloudRole} from 'shared/src/user/types';
import {useAuth} from '../../components/Auth/AuthContext';
import {NavLayout} from '../../components/Layout/NavLayout';

// markup
const NotificationsPage = (props: PageProps) => {
  // @ts-ignore
  const {user} = useAuth();
  if (
    !user?.['https://cribl.cloud/cloudRole']?.find(
      (role) => role === CriblCloudRole.Admin || role === CriblCloudRole.Support
    )
  )
    return (
      <div className='main-error'>
        <Result status='error' title='Permission Denied' subTitle='You do not had permission to access this page' />
      </div>
    );

  setUTMCookie(props);
  return (
    <>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet='utf-8' />
        <title>Cribl.Cloud - Send Notification</title>
        <link rel='canonical' href='https://cribl.cloud/admin/notifications' />
      </Helmet>
      <main key={'notification'} className={'main-holder'}>
        <NavLayout>
          <Notifications />
        </NavLayout>
      </main>
    </>
  );
};

export default NotificationsPage;
